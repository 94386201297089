/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type PATHS = {

	// 面客平台
	readonly DESK2_SIGN: string;
	readonly DESK2_SIGN_IN: string;
	readonly DESK2_SIGN_IN_VENDOR: string;
	readonly DESK2_SIGN_UP: string;
	readonly DESK2_SIGN_UP_VENDOR: string;
	readonly DESK2_RESET_PASSWORD: string;
	readonly DESK2_RESET_PASSWORD_VENDOR: string;

	readonly DESK2_HOME: string;
	readonly DESK2_HOME_VENDOR: string;
	readonly DESK2_NOT_FOUND: string;

	readonly DESK2_PRODUCT: string;
	readonly DESK2_PRODUCT_MAIN: string;
	readonly DESK2_PRODUCT_MAIN_VENDOR: string;
	readonly DESK2_PRODUCT_SEARCH_RESULT: string;
	readonly DESK2_PRODUCT_SEARCH_RESULT_VENDOR: string;
	readonly DESK2_ENTERPRISE_EXCLUSIVE: string;
	readonly DESK2_ENTERPRISE_EXCLUSIVE_VENDOR: string;
	readonly DESK2_DISCLOSURE: string;
	readonly DESK2_DISCLOSURE_MAIN: string;
	readonly DESK2_DISCLOSURE_MAIN_VENDOR: string;
	readonly DESK2_BUSINESS_QUALIFICATION: string;
	readonly DESK2_PRIVACY_POLICY: string;
	readonly DESK2_ABOUT_US: string;
	readonly DESK2_ABOUT_US_VENDOR: string;
	readonly DESK2_HELP: string;
	readonly DESK2_HELP_VENDOR: string;
	readonly DESK2_DISCLOSURE_PRODUCT: string;
	readonly DESK2_DISCLOSURE_PRODUCT_VENDOR: string;
	readonly DESK2_DISCLOSURE_INSURER: string;
	readonly DESK2_DISCLOSURE_INSURER_VENDOR: string;
	readonly DESK2_COOKIE_STATEMENT: string;
	readonly DESK2_COOKIE_STATEMENT_VENDOR: string;
	readonly DESK2_STATEMENT: string;
	readonly DESK2_STATEMENT_VENDOR: string;
	readonly DESK2_AGREEMENT: string;
	readonly DESK2_AGREEMENT_VENDOR: string;

	readonly DESK2_MOBILE_IFRAME: string;

	readonly DESK2_PERSONAL_CENTER: string;
	readonly DESK2_PERSONAL_POLICY: string;
	readonly DESK2_PERSONAL_POLICY_VENDOR: string;
	readonly DESK2_PERSONAL_SETTING: string;
	readonly DESK2_PERSONAL_SETTING_VENDOR: string;
	readonly DESK2_PERSONAL_FAMILY_MEMBER: string;
	readonly DESK2_PERSONAL_FAMILY_MEMBER_VENDOR: string;
	readonly DESK2_PERSONAL_CUSTOMER_SERVICE: string;
	readonly DESK2_PERSONAL_CUSTOMER_SERVICE_VENDOR: string;

	readonly DESK2_PERSONAL_MODIFY_MOBILE: string;
	readonly DESK2_PERSONAL_MODIFY_MOBILE_VENDOR: string;
	readonly DESK2_PERSONAL_MODIFY_PWD: string;
	readonly DESK2_PERSONAL_MODIFY_PWD_VENDOR: string;
	readonly DESK2_PERSONAL_AGREEMENT: string;
	readonly DESK2_PERSONAL_AGREEMENT_VENDOR: string;
	readonly DESK2_PERSONAL_STATEMENT: string;
	readonly DESK2_PERSONAL_STATEMENT_VENDOR: string;
	readonly DESK2_PERSONAL_COOKIE_STATEMENT: string;
	readonly DESK2_PERSONAL_COOKIE_STATEMENT_VENDOR: string;
	// readonly DESK2_PERSONAL_CANCEL_ACCOUNT: string;

	readonly MOBILE_POLICY_INFO: string;
	readonly MOBILE_POLICY_INFO_UNLOGIN: string;
	readonly MOBILE_POLICY_INFO_SHOW: string;
	readonly MOBILR_POLICY_QUOTE: string;
	readonly MOBILE_POLICY_VIEW_BYQUOTE: string;
	readonly MALL_MOBILE_SIGN_IN: string;
	readonly MALL_MOBILE_HOME: string;
};

export default {


	// 面客平台
	DESK2_SIGN: '/pc/sign',
	DESK2_SIGN_IN: '/pc/sign/in',
	DESK2_SIGN_IN_VENDOR: '/pc/sign/in/:vendor',
	DESK2_SIGN_UP: '/pc/sign/up',
	DESK2_SIGN_UP_VENDOR: '/pc/sign/up/:vendor',
	DESK2_RESET_PASSWORD: '/pc/reset-pwd',
	DESK2_RESET_PASSWORD_VENDOR: '/pc/reset-pwd/:vendor',

	DESK2_HOME: '/pc/', // 首页
	DESK2_HOME_VENDOR: '/pc/:vendor',
	DESK2_NOT_FOUND: '/pc/404',

	DESK2_PRODUCT: '/pc/product',
	DESK2_PRODUCT_MAIN: '/pc/product/main', // 保险产品
	DESK2_PRODUCT_MAIN_VENDOR: '/pc/product/main/:vendor',
	DESK2_PRODUCT_SEARCH_RESULT: '/pc/product/search/:keyword', // 保险产品搜搜结果页
	DESK2_PRODUCT_SEARCH_RESULT_VENDOR: '/pc/product/search/:vendor/:keyword', // 保险产品搜搜结果页
	DESK2_ENTERPRISE_EXCLUSIVE: '/pc/enterprise', // 企业专属
	DESK2_ENTERPRISE_EXCLUSIVE_VENDOR: '/pc/enterprise/:vendor',
	DESK2_DISCLOSURE: '/pc/disclosure', // 信息披露
	DESK2_DISCLOSURE_MAIN: '/pc/disclosure/main',
	DESK2_DISCLOSURE_MAIN_VENDOR: '/pc/disclosure/main/:vendor',
	DESK2_BUSINESS_QUALIFICATION: '/pc/disclosure/qualification', // 经营资质
	DESK2_PRIVACY_POLICY: '/pc/disclosure/privacy', // 隐私政策
	DESK2_ABOUT_US: '/pc/about', // 关于我们
	DESK2_ABOUT_US_VENDOR: '/pc/about/:vendor',
	DESK2_HELP: '/pc/help', // 帮助中心
	DESK2_HELP_VENDOR: '/pc/help/:vendor',
	DESK2_DISCLOSURE_PRODUCT: '/pc/disclosure/product', // 互联网保险产品信息披露
	DESK2_DISCLOSURE_PRODUCT_VENDOR: '/pc/disclosure/product/:vendor', // 互联网保险产品信息披露
	DESK2_DISCLOSURE_INSURER: '/pc/disclosure/insurer', // 合作保险公司信息披露
	DESK2_DISCLOSURE_INSURER_VENDOR: '/pc/disclosure/insurer/:vendor', // 合作保险公司信息披露
	DESK2_COOKIE_STATEMENT: '/pc/disclosure/cookie',
	DESK2_COOKIE_STATEMENT_VENDOR: '/pc/disclosure/cookie/:vendor',
	DESK2_STATEMENT: '/pc/disclosure/statement',
	DESK2_STATEMENT_VENDOR: '/pc/disclosure/statement/:vendor',
	DESK2_AGREEMENT: '/pc/disclosure/agreement',
	DESK2_AGREEMENT_VENDOR: '/pc/disclosure/agreement/:vendor',

	DESK2_MOBILE_IFRAME: '/pc/mobile-iframe',

	DESK2_PERSONAL_CENTER: '/pc/personal', // 个人中心
	DESK2_PERSONAL_POLICY: '/pc/personal/policy', // 我的保单
	DESK2_PERSONAL_POLICY_VENDOR: '/pc/personal/policy/:vendor', // 我的保单

	DESK2_PERSONAL_SETTING: '/pc/personal/setting', // 个人设置	
	DESK2_PERSONAL_SETTING_VENDOR: '/pc/personal/setting/:vendor', // 个人设置	
	DESK2_PERSONAL_FAMILY_MEMBER: '/pc/personal/family-member', // 家庭成员	
	DESK2_PERSONAL_FAMILY_MEMBER_VENDOR: '/pc/personal/family-member/:vendor', // 家庭成员
	DESK2_PERSONAL_CUSTOMER_SERVICE: '/pc/personal/customer-service', // 客户服务
	DESK2_PERSONAL_CUSTOMER_SERVICE_VENDOR: '/pc/personal/customer-service/:vendor', // 客户服务


	// DESK2_PERSONAL_SETTING: '/pc/personal/setting', // 个人信息
	DESK2_PERSONAL_MODIFY_MOBILE: '/pc/personal/modify-mobile', // 安全手机修改
	DESK2_PERSONAL_MODIFY_MOBILE_VENDOR: '/pc/personal/modify-mobile/:vendor', // 安全手机修改
	DESK2_PERSONAL_MODIFY_PWD: '/pc/personal/modify-pwd', // 账号密码修改
	DESK2_PERSONAL_MODIFY_PWD_VENDOR: '/pc/personal/modify-pwd/:vendor', // 账号密码修改
	DESK2_PERSONAL_AGREEMENT: '/pc/personal/agreement', // 用户协议
	DESK2_PERSONAL_AGREEMENT_VENDOR: '/pc/personal/agreement/:vendor', // 用户协议
	DESK2_PERSONAL_STATEMENT: '/pc/personal/statement', // 个人信息收集声明
	DESK2_PERSONAL_STATEMENT_VENDOR: '/pc/personal/statement/:vendor', // 个人信息收集声明
	DESK2_PERSONAL_COOKIE_STATEMENT: '/pc/personal/cookie-statement', // Cookie声明
	DESK2_PERSONAL_COOKIE_STATEMENT_VENDOR: '/pc/personal/cookie-statement/:vendor', // Cookie声明
	// DESK2_PERSONAL_CANCEL_ACCOUNT: '/pc/personal/cancel-account' // 注销账户

	MOBILE_POLICY_INFO: '/mobile/policy/info/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_INFO_UNLOGIN: '/mobile/policy/info/unlogin/:vendorCode/:productCode/:productVersion/:tenantCode',
	MOBILE_POLICY_INFO_SHOW: '/mobile/policy/info/show/:vendorCode/:productCode/:productVersion/:tenantCode',
	MOBILR_POLICY_QUOTE: '/mobile/policy/quote/:vendorCode/:productCode/:productVersion',
	MOBILE_POLICY_VIEW_BYQUOTE: '/mobile/policy/view/byquote/:quoteNo',
	MALL_MOBILE_SIGN_IN: '/mall/sign/in',
	MALL_MOBILE_HOME: '/mall/home'

} as PATHS;
